import {memo} from 'react';
import {Table} from 'reactstrap';

import {formatDate, formatDecimal, formatInteger} from '../../../utils';
import {StudyPeriods, StudyPeriodTotals} from '../../../types';

type Props = {
  studyPeriods: StudyPeriods
  studyPeriodTotals: StudyPeriodTotals[]
  headerText: string
}

const Form4015TotalsTable = ({
                               studyPeriods,
                               studyPeriodTotals,
                               headerText
                             }: Props) => {
  return <div className="mb-4">
    <Table responsive bordered>
      <thead>
        <tr>
          <th className="text-primary align-middle">
            {headerText} Totals
          </th>
          <th className="text-primary align-middle text-center">
            Number of Sales
          </th>
          <th className="text-primary align-middle text-center">
            Assessed Value
          </th>
          <th className="text-primary align-middle text-center">
            Sale Price
          </th>
          <th className="text-primary align-middle text-center">
            Adjusted Price
          </th>
          <th className="text-primary align-middle text-center">
            Ratio
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(studyPeriods).map((key, index) => {
          const duration = studyPeriods[Number(key)];
          const totals = studyPeriodTotals[index];
          return (<tr key={key}>
            <td className="align-middle font-weight-bold text-primary">
              {formatDate(duration.begin)}-{formatDate(duration.end)}
            </td>
            <td className="align-middle text-center">
              {formatInteger(totals.numberOfProperties)}
            </td>
            <td className="align-middle text-center">
              {formatInteger(totals.assessedValue)}
            </td>
            <td className="align-middle text-center">
              {formatInteger(totals.salesPrice)}
            </td>
            <td className="align-middle text-center">
              {formatInteger(totals.adjustedSalesPrice)}
            </td>
            <td className="align-middle text-center">
              {formatDecimal(totals.ratio, 2, true)}
            </td>
          </tr>);
        })}
      </tbody>
    </Table>
  </div>;
};

export default memo(Form4015TotalsTable);