import {memo, useMemo} from 'react';
import {Badge} from 'reactstrap';

import {FormStatus} from '../../enum';

type Props = {
  status: string
}

const LocalUnitStatusBadge = ({
                                status
                              }: Props) => {
  const color = useMemo(() => {
    if (status === FormStatus.ACCEPTED ||
      status === FormStatus.SUBMITTED ||
      status === FormStatus.SUBMITTED_TO_COUNTY) {
      return 'success';
    } else if (status === FormStatus.RETURNED || status === FormStatus.SENT_TO_REVIEW_TO_LOCAL_UNIT) {
      return 'danger';
    } else {
      return 'light';
    }
  }, [status]);

  const statusDisplayValue = useMemo(() => {
    if (status === FormStatus.SENT_TO_REVIEW_TO_LOCAL_UNIT) {
      return FormStatus.RECEIVED;
    } else {
      return status.replace(/_/g, ' ');
    }
  }, [status]);

  return (
    <Badge color={color}
           className="font-size-100 text-uppercase border-0">
      {statusDisplayValue}
    </Badge>
  );
};

export default memo(LocalUnitStatusBadge);