import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {
  AlertsProvider,
  configureRaygun,
  MiSuiteJurisdiction,
  MiSuiteRole,
  ssoUtils,
  User,
  UserProvider
} from '@reasoncorp/kyber-js';

import packageJson from '../package.json';
import './scss/index.scss';
import App from './App';
import config from './config';
import {ssoApi} from './api';
import * as messages from './messages';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${messages.APP_TITLE}`;
}

const raygunOptions = {
  apiKey: process.env.REACT_APP_RAYGUN_API_KEY || 'apikeygoeshere',
  version: packageJson.version,
  tags: [config.envName]
};

configureRaygun(raygunOptions);

const setPermissions = (currentUser?: User) => ({
  isAdmin: ssoUtils.hasMiSuiteAdminRole(currentUser, MiSuiteJurisdiction.MEG_APP),
  isStateUser: ssoUtils.hasMiSuiteAdminRole(currentUser, MiSuiteJurisdiction.MEG_APP) ||
    ssoUtils.hasAnyRoles(currentUser, [MiSuiteRole.FOS_ANALYST, MiSuiteRole.FOS_SUPERVISOR]),
  isCountyUser: ssoUtils.hasAnyRoles(currentUser, [
    MiSuiteRole.EQUALIZATION_CLERK,
    MiSuiteRole.EQUALIZATION_DEPUTY,
    MiSuiteRole.EQUALIZATION_DIRECTOR]),
  hasCountySubmitAccess: ssoUtils.hasAnyRoles(currentUser, [
    MiSuiteRole.EQUALIZATION_DEPUTY,
    MiSuiteRole.EQUALIZATION_DIRECTOR]),
  isLocalUnitUser: ssoUtils.hasAnyRoles(currentUser, [
    MiSuiteRole.ASSESSOR_OF_RECORD,
    MiSuiteRole.CITY_MANAGER,
    MiSuiteRole.TOWNSHIP_SUPERVISOR,
    MiSuiteRole.ASSESSING_CLERK,
    MiSuiteRole.ASSESSING_DEPUTY]),
  hasCountyLockAccess: ssoUtils.hasMiSuiteAdminRole(currentUser, MiSuiteJurisdiction.MEG_APP) ||
    ssoUtils.hasAnyRoles(currentUser, [MiSuiteRole.FOS_SUPERVISOR])
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <AlertsProvider>
      <UserProvider ssoApi={ssoApi} setPermissions={setPermissions}>
        <App/>
      </UserProvider>
    </AlertsProvider>
  </BrowserRouter>
);