import {useCallback, useMemo, useState} from 'react';

import {Button, Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {Formik} from 'formik';

import {ConfirmationModal, CustomTable, FormikCheckboxGroup, TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {
  filterPropertiesByClassification,
  formatDecimal,
  formatInteger,
  PROPERTY_CLASSIFICATION_TABS,
  sumObjects
} from '../../utils';
import {Form4015aPropertyModal, Form4015aPropertyRow} from '../../components/forms/form4015a';
import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  LocalUnitFormNavigator,
  NoPropertiesModal,
  StateFormButtons,
  StateFormModals
} from '../../components/shared';
import * as messages from '../../messages';
import {AmendmentRequest, forms, LocalUnitFormDto, ReturnRequest} from '../../types';

type Props = {
  form: LocalUnitFormDto
  loading: boolean
  isStateUser: boolean
  submitButtonText: string
  onReturn: (returnRequest: ReturnRequest) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.LocalUnitFormData) => void
  onSubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onResubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onSave: (localUnitFormData: forms.LocalUnitFormData) => void,
  onAccept: () => void,
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  isLocalUnitUser: boolean
}

const Form4015a = ({
                     form,
                     loading,
                     isStateUser,
                     submitButtonText,
                     onReturn,
                     onAmend,
                     onSubmit,
                     onResubmit,
                     onSave,
                     setHasUnsavedChanges,
                     onAccept,
                     isLocalUnitUser
                   }: Props) => {
    const form4015aDto = useMemo(() => (
      // Use latest form submission data if available for state users
      isStateUser && form.latestSubmissionData !== null ? form.latestSubmissionData : form.data
    ) as forms.Form4015aDto, [form, isStateUser]);
    const [noPropertiesModalIsOpen, setNoPropertiesModalIsOpen] = useState(false);
    const [noPropertiesSelections, setNoPropertiesSelections] = useState(form4015aDto.noPropertiesInClassification);
    const [stratifiedClassifications, setStratifiedClassifications] = useState(form4015aDto.stratifiedClassifications);
    const [propertyModalIsOpen, setPropertyModalIsOpen] = useState(false);
    const [propertyModalOperation, setPropertyModalOperation] = useState('Add');
    const [selectedProperty, setSelectedProperty] = useState<forms.Form4015aPropertyDto | undefined>(undefined);
    const [nextId, setNextId] = useState(form4015aDto.properties.length + 1);
    const [filteredProperties, setFilteredProperties] = useState(filterPropertiesByClassification(form4015aDto.properties));
    const [propertyDeleteModalIsOpen, setPropertyDeleteModalIsOpen] = useState(false);
    const [submitFormModalIsOpen, setSubmitFormModalIsOpen] = useState(false);
    const [resubmitFormModalIsOpen, setResubmitFormModalIsOpen] = useState(false);
    const [acceptFormModalIsOpen, setAcceptFormModalIsOpen] = useState(false);
    const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
    const {selectedTab} = useTabNav();

    const getSaveableData = useCallback(() => {
      let properties: forms.Form4015aPropertyDto[] = [];
      Object.keys(filteredProperties).forEach(
        key => properties = [...properties, ...filteredProperties[key]]
      );
      return {
        type: 'FORM_4015A' as const,
        noPropertiesInClassification: noPropertiesSelections,
        stratifiedClassifications,
        properties
      } as forms.Form4015aDto;
    }, [
      filteredProperties,
      noPropertiesSelections,
      stratifiedClassifications
    ]);

    const handleSave = useCallback(() => {
      onSave(getSaveableData());
    }, [
      onSave,
      getSaveableData
    ]);

    const handleSubmit = useCallback(() => {
      onSubmit(getSaveableData());
    }, [
      onSubmit,
      getSaveableData
    ]);

    const handleResubmit = useCallback(() => {
      onResubmit(getSaveableData());
    }, [
      onResubmit,
      getSaveableData
    ]);

    const handleAmend = useCallback((amendmentRequest: AmendmentRequest) => {
      onAmend(amendmentRequest, getSaveableData());
    }, [
      onAmend,
      getSaveableData
    ]);

    const handleNoPropertiesSelection = useCallback(() => {
      // If it was selected let the user unselect with no prompts, otherwise display the confirmation modal
      if (selectedTab && noPropertiesSelections[selectedTab]) {
        const updatedClassificationsBooleanMap = ({
          ...noPropertiesSelections,
          [selectedTab as string]: false
        }) as forms.ClassificationsBooleanMap;
        setNoPropertiesSelections(updatedClassificationsBooleanMap);
        setHasUnsavedChanges(true);
      } else {
        setNoPropertiesModalIsOpen(true);
      }
    }, [
      noPropertiesSelections,
      selectedTab,
      setHasUnsavedChanges
    ]);

    const handleStratifiedChange = useCallback(() => {
      if (selectedTab) {
        const updatedStratifiedClassifications = ({
          ...stratifiedClassifications,
          [selectedTab as string]: !stratifiedClassifications[selectedTab as string]
        }) as forms.ClassificationsBooleanMap;
        setStratifiedClassifications(updatedStratifiedClassifications);
        setHasUnsavedChanges(true);
      }
    }, [
      selectedTab,
      setHasUnsavedChanges,
      stratifiedClassifications
    ]);

    const isFormSubmittable = useMemo(() => {
      return Object.keys(noPropertiesSelections)
        .every(classification => noPropertiesSelections[classification] || filteredProperties[classification].length > 0);
    }, [
      noPropertiesSelections,
      filteredProperties
    ]);

    const toggleSubmitModal = useCallback((confirmSubmit = false) => {
      if (confirmSubmit) {
        handleSubmit();
      }
      setSubmitFormModalIsOpen(!submitFormModalIsOpen);
    }, [
      handleSubmit,
      submitFormModalIsOpen
    ]);

    const toggleResubmitModal = useCallback((confirmResubmit = false) => {
      if (confirmResubmit) {
        handleResubmit();
      }
      setResubmitFormModalIsOpen(!resubmitFormModalIsOpen);
    }, [
      resubmitFormModalIsOpen,
      handleResubmit
    ]);

    const toggleAcceptFormModal = useCallback((confirmSubmit = false) => {
      if (confirmSubmit) {
        onAccept();
      }
      setAcceptFormModalIsOpen(!acceptFormModalIsOpen);
    }, [
      onAccept,
      acceptFormModalIsOpen
    ]);

    const toggleCommentModal = useCallback(() => setCommentModalIsOpen(!commentModalIsOpen), [commentModalIsOpen]);

    const toggleNoPropertiesModal = useCallback((confirmNoProperties = false) => {
      if (noPropertiesModalIsOpen && confirmNoProperties) {
        const noPropertiesSelectionsCopy = {...noPropertiesSelections, [selectedTab as string]: confirmNoProperties};
        const filteredPropertiesCopy = {...filteredProperties, [selectedTab as string]: []};
        setFilteredProperties(filteredPropertiesCopy);
        setNoPropertiesSelections(noPropertiesSelectionsCopy);
        setNoPropertiesModalIsOpen(false);
        setHasUnsavedChanges(true);
      } else if (noPropertiesModalIsOpen && !confirmNoProperties) {
        setNoPropertiesModalIsOpen(false);
      } else {
        setNoPropertiesModalIsOpen(true);
      }
    }, [
      noPropertiesSelections,
      filteredProperties,
      noPropertiesModalIsOpen,
      selectedTab,
      setHasUnsavedChanges
    ]);

    const togglePropertyModal = useCallback((operation: 'Add' | 'Edit', property?: forms.Form4015aPropertyDto) => {
      if (!propertyModalIsOpen) {
        setPropertyModalIsOpen(true);
        setPropertyModalOperation(operation);
        if (operation === 'Add') {
          setSelectedProperty(undefined);
        } else if (operation === 'Edit' && property) {
          setSelectedProperty(property);
        }
      } else {
        // The conditional blocks handle if a successful add, successful edit, or if a cancel happened.
        setHasUnsavedChanges(true);
        if (operation === 'Add' && property) {
          const filteredPropertiesCopy = {...filteredProperties};
          property.id = nextId;
          (filteredPropertiesCopy[selectedTab as string] as forms.Form4015aPropertyDto[]).push(property);
          setFilteredProperties(filteredPropertiesCopy);

          if (property.stratified && !stratifiedClassifications[selectedTab as string]) {
            handleStratifiedChange();
          }

          setNextId(nextId + 1);
        } else if (operation === 'Edit' && property) {
          const filteredPropertiesCopy = {...filteredProperties};
          const index = filteredPropertiesCopy[selectedTab as string]
            .findIndex((item: forms.Form4014aPropertyDto) => item.id === property?.id);
          if (index !== -1) {
            (filteredPropertiesCopy[selectedTab as string] as forms.Form4015aPropertyDto[])[index] = property;
          }
          setFilteredProperties(filteredPropertiesCopy);

          if (property.stratified && !stratifiedClassifications[selectedTab as string]) {
            handleStratifiedChange();
          }
        }

        setPropertyModalIsOpen(false);
      }
    }, [
      filteredProperties,
      handleStratifiedChange,
      nextId,
      propertyModalIsOpen,
      selectedTab,
      setHasUnsavedChanges,
      stratifiedClassifications
    ]);

    const toggleDeleteModal = useCallback((property?: forms.Form4015aPropertyDto) => {
      if (propertyDeleteModalIsOpen) {
        // If property has a value while the modal is open then attempt to delete it
        if (property) {
          setHasUnsavedChanges(true);
          const filteredPropertiesCopy = {...filteredProperties};
          const index = filteredProperties[selectedTab as string]
            .findIndex((item: forms.Form4015PropertyDto) => item.id === property.id);
          filteredPropertiesCopy[selectedTab as string].splice(index, 1);
          setFilteredProperties(filteredPropertiesCopy);
        }

        setPropertyDeleteModalIsOpen(false);
      } else if (property) {
        setSelectedProperty(property);
        setPropertyDeleteModalIsOpen(true);
      }
    }, [
      propertyDeleteModalIsOpen,
      selectedTab,
      setHasUnsavedChanges,
      filteredProperties
    ]);

    const showReadOnlyView = useMemo(() => {
      return isStateUser || isLocalUnitUser || form.locked;
    }, [
      isStateUser,
      isLocalUnitUser,
      form.locked
    ]);

    const itemsForSelectedClassification = useMemo(() => {
      return selectedTab ? filteredProperties[selectedTab as string] : [];
    }, [
      selectedTab,
      filteredProperties
    ]);

    const stratifiedItemsForClassification = useMemo(() => {
      return itemsForSelectedClassification.filter(property => property.stratified);
    }, [
      itemsForSelectedClassification
    ]);

    const unstratifiedItemsForClassification = useMemo(() => {
      return itemsForSelectedClassification.filter(property => property.stratified === null || !property.stratified);
    }, [
      itemsForSelectedClassification
    ]);

    const renderFooter = useMemo(() => ({
                                          properties
                                        }: {
      properties: forms.Form4015aPropertyDto[]
    }) => () => {
      const assessedValueSum = sumObjects(properties, 'assessedValue');
      const appraisedValueSum = sumObjects(properties, 'appraisedValue');
      const ratio = appraisedValueSum !== 0 ? assessedValueSum / appraisedValueSum : 0;

      return <>
        <tr style={{borderTop: '3px solid #CCC'}}>
          <td className="font-weight-bold text-primary" colSpan={3}>
            Totals ({properties.length} Parcels)
          </td>
          <td className="font-weight-bold text-center">{formatInteger(assessedValueSum)}</td>
          <td className="font-weight-bold text-center">{formatInteger(appraisedValueSum)}</td>
          <td className="font-weight-bold text-center">{formatDecimal(ratio, 2, true)}</td>
          {form.version === 'V1' && <td/>}
          {!showReadOnlyView && <>
            <td/>
            <td/>
          </>}
        </tr>
      </>;
    }, [
      form.version,
      showReadOnlyView
    ]);

    const tableProps = useMemo(() => ({
      headers: [{
        className: 'align-middle text-primary',
        title: 'Parcel Code'
      }, {
        className: 'align-middle text-primary',
        title: 'Owner\'s Name'
      }, {
        className: 'text-center align-middle text-primary',
        title: 'Class Code'
      }, {
        className: 'text-center align-middle text-primary',
        title: 'Assessed Value'
      }, {
        className: 'text-center align-middle text-primary',
        title: 'Appraised Value'
      }, {
        className: 'text-center align-middle text-primary',
        title: 'Ratio'
      }, {
        className: 'align-middle text-primary',
        title: 'Comments',
        hide: form.version !== 'V1'
      }, {
        className: 'text-center align-middle text-primary',
        title: 'Edit Parcel',
        hide: showReadOnlyView
      }, {
        className: 'text-center align-middle text-primary',
        title: 'Delete Parcel',
        hide: showReadOnlyView
      }],
      headerRowClassName: 'text-primary',
      renderRow: (item: forms.Form4015aPropertyDto) =>
        <Form4015aPropertyRow property={item}
                              key={item.id}
                              version={form.version}
                              showReadOnlyView={showReadOnlyView}
                              onEdit={() => togglePropertyModal('Edit', item)}
                              onDelete={() => toggleDeleteModal(item)}/>,
      noResultsMessage: messages.NO_PARCELS_ADDED,
      paginatorConfig: {
        perPage: 100,
        recordName: 'parcels',
        allowShowAll: true
      }
    }), [
      togglePropertyModal,
      toggleDeleteModal,
      showReadOnlyView,
      form.version
    ]);

    const unstratifiedTableProps = useMemo(() => ({
      ...tableProps,
      items: stratifiedClassifications[selectedTab as string] ? unstratifiedItemsForClassification : itemsForSelectedClassification,
      renderFooter: renderFooter({
        properties: stratifiedClassifications[selectedTab as string] ? unstratifiedItemsForClassification : itemsForSelectedClassification
      })
    }), [
      tableProps,
      unstratifiedItemsForClassification,
      stratifiedClassifications,
      selectedTab,
      itemsForSelectedClassification,
      renderFooter
    ]);

    const stratifiedTableProps = useMemo(() => ({
      ...tableProps,
      items: stratifiedItemsForClassification,
      renderFooter: renderFooter({
        properties: stratifiedItemsForClassification
      })
    }), [
      tableProps,
      renderFooter,
      stratifiedItemsForClassification
    ]);

    const renderTables = useMemo(() => () => {
      if (form.version === 'V2') {
        return <>
          <Card className="mb-4">
            <CardHeader>Form 4504 (L-4015a) Appraisal Study Listing</CardHeader>
            <CustomTable {...unstratifiedTableProps}/>
          </Card>
          {stratifiedClassifications[selectedTab as string] as boolean && <>
            <div className="mt-4 mb-4 p-2" style={{background: '#EEE'}}/>
            <Card className="mb-4">
              <CardHeader>Stratified</CardHeader>
              <CustomTable {...stratifiedTableProps}/>
            </Card>
          </>}
        </>;
      } else {
        return <CustomTable {...unstratifiedTableProps}/>;
      }
    }, [
      form.version,
      stratifiedClassifications,
      selectedTab,
      stratifiedTableProps,
      unstratifiedTableProps
    ]);

    const shouldDisplayStateFormButtons = useMemo(() => {
      return isStateUser && form.status !== 'IN_PROGRESS';
    }, [
      isStateUser,
      form.status
    ]);

    const shouldDisplayFormActionButtons = useMemo(() => {
      return !isStateUser && !isLocalUnitUser;
    }, [
      isStateUser,
      isLocalUnitUser
    ]);

    const headerCheckboxProps = useMemo(() => form.version === 'V1' ? [
      {
        name: `noPropertiesSelections['${[selectedTab as string]}']`,
        labelText: 'No Parcels in Class or Form needed',
        noFormikOnChange: true,
        onChange: () => handleNoPropertiesSelection()
      }
    ] : [
      {
        name: `noPropertiesSelections['${[selectedTab as string]}']`,
        labelText: 'No Parcels in Class or Form needed',
        noFormikOnChange: true,
        onChange: () => handleNoPropertiesSelection()
      },
      {
        name: `stratifiedClassifications['${[selectedTab as string]}']`,
        labelText: 'Stratified',
        onChange: () => handleStratifiedChange()
      }], [
      handleStratifiedChange,
      handleNoPropertiesSelection,
      selectedTab,
      form.version
    ]);

    return (
      <div className="Form4015a">
        <Card className="mb-3">
          <FormHeader form={form}/>
          <CardHeader className="nav-tabs-header">
            <TabNav/>
          </CardHeader>
          <CardBody>
            {!showReadOnlyView &&
              <Formik initialValues={{
                stratifiedClassifications,
                noPropertiesSelections
              }}
                      onSubmit={async () => null}
                      enableReinitialize={true}>
                {() => (<>
                  <Row className="mb-4">
                    <Col xs="12" md="6">
                      <FormikCheckboxGroup inline
                                           disabled={loading}
                                           type="switch"
                                           checkboxes={headerCheckboxProps}/>
                    </Col>
                    <Col xs="12" md="6" className="d-flex justify-content-end">
                      {!noPropertiesSelections[selectedTab as string] &&
                        <Button color="primary"
                                onClick={() => togglePropertyModal('Add')}
                                disabled={loading}>
                          Add Parcel
                        </Button>
                      }
                    </Col>
                  </Row>
                </>)}
              </Formik>}
            <Form4015aPropertyModal isOpen={propertyModalIsOpen}
                                    version={form.version}
                                    operation={propertyModalOperation as ('Add' | 'Edit')}
                                    classificationFilter={selectedTab as string}
                                    property={selectedProperty}
                                    onToggle={togglePropertyModal}/>
            {!noPropertiesSelections[selectedTab as string] && renderTables()}
          </CardBody>
        </Card>

        <FormHistoryTable items={form.formHistory}/>

        {shouldDisplayFormActionButtons && <>
          <FormActionButtons saveDisabled={loading || form.locked}
                             submitDisabled={loading || !isFormSubmittable || form.locked}
                             submitButtonText={submitButtonText}
                             onSave={handleSave}
                             onToggleCommentModal={toggleCommentModal}
                             onToggleResubmitModal={() => toggleResubmitModal(false)}
                             onToggleSubmitModal={() => toggleSubmitModal(false)}/>
        </>}

        {shouldDisplayStateFormButtons && <>
          <StateFormButtons loading={loading}
                            onAcceptClick={() => toggleAcceptFormModal()}
                            onReturnClick={() => toggleCommentModal()}/>
          <StateFormModals onReturn={onReturn}
                           returnModalIsOpen={commentModalIsOpen}
                           onReturnCancel={toggleCommentModal}
                           onAccept={() => toggleAcceptFormModal(true)}
                           acceptModalIsOpen={acceptFormModalIsOpen}
                           onAcceptCancel={() => toggleAcceptFormModal()}
                           form={form}/>
        </>}

        {!isLocalUnitUser && <LocalUnitFormNavigator localUnitForm={form}
                                                     isStateUser={isStateUser}/>}

        {!isStateUser && <>
          <NoPropertiesModal onToggle={toggleNoPropertiesModal}
                             isOpen={noPropertiesModalIsOpen}
                             selectedClassificationFilter={selectedTab as string}
                             title="Confirm No Parcels or Form needed"
                             bodyTextEnding="class or form needed"/>

          {selectedProperty && <ConfirmationModal isOpen={propertyDeleteModalIsOpen}
                                                  size="lg"
                                                  title="Delete Parcel"
                                                  confirmButtonColor="primary"
                                                  confirmButtonText="Yes"
                                                  cancelButtonText="No"
                                                  confirmCallback={() => toggleDeleteModal(selectedProperty)}
                                                  cancelCallback={() => toggleDeleteModal()}>
            <p>
              Are you sure you want to delete <span className="text-danger">{selectedProperty.propertyNumber}</span> from the Appraisal
              Study Listing form for {form.localUnitDisplayName}?
            </p>
          </ConfirmationModal>}

          <FormModals form={form}
                      resubmitFormModalIsOpen={resubmitFormModalIsOpen}
                      submitFormModalIsOpen={submitFormModalIsOpen}
                      amendModalIsOpen={commentModalIsOpen}
                      onSubmit={() => toggleSubmitModal(true)}
                      onCancelSubmit={toggleSubmitModal}
                      onResubmit={() => toggleResubmitModal(true)}
                      onResubmitCancel={toggleResubmitModal}
                      onAmend={handleAmend}
                      toggleAmend={toggleCommentModal}/>
        </>}
      </div>
    );
  }
;

export default withTabNav(Form4015a, {tabs: PROPERTY_CLASSIFICATION_TABS});