import {Col, Row, Table} from 'reactstrap';

import {FormikInput, FormikNumberInput, FormikPercentageInput, FormikSelect} from '@reasoncorp/kyber-js';

import {forms} from '../../../types';
import * as form4018V2Utils from '../../../utils/form4018V2Utils';
import {formatDecimal, formatInteger, STUDY_TYPES_PERSONAL_4018} from '../../../utils';

type Props = {
  form4018V2Dto: forms.Form4018V2Dto
  showReadOnlyView: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
}

const Form4018V2PersonalPropertyTab = ({
                                         form4018V2Dto,
                                         showReadOnlyView,
                                         setHasUnsavedChanges
                                       }: Props) => {
  const totals = form4018V2Utils.calculateTotals(form4018V2Dto);

  return <>
    <div className="mb-4">
      <Table responsive bordered>
        <thead>
          <tr>
            <th colSpan={3} className="text-primary text-center align-middle">Assessment Roll Classification</th>
            <th colSpan={3} className="text-primary text-center align-middle border-left-3">Sample</th>
            <th colSpan={2} className="text-primary text-center align-middle border-left-3"/>
          </tr>
          <tr>
            <th className="text-primary text-center align-middle">Class of Personal Property</th>
            <th className="text-primary text-center align-middle">Study Type</th>
            <th className="text-primary text-center align-middle">Assessed Value</th>
            <th className="text-primary text-center align-middle border-left-3">Number of Parcels</th>
            <th className="text-primary text-center align-middle">Assessed Value</th>
            <th className="text-primary text-center align-middle">True Cash Value</th>
            <th className="text-primary text-center align-middle border-left-3">Study Ratio</th>
            <th className="text-primary text-center align-middle">Projected True Cash Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries({
            '150': 'Agricultural',
            '250': 'Commercial',
            '350': 'Industrial',
            '450': 'Residential',
            '550': 'Utility'
          }).map(([classification, classificationName]) => {
            const classificationData = form4018V2Dto.personalProperty.classifications[classification as unknown as forms.PersonalPropertyClassificationCode];
            return <tr key={classification}>
              <td className="align-middle text-center text-nowrap text-primary font-weight-bold">
                {classification} - {classificationName}
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && classificationData.studyType}
                {!showReadOnlyView && <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                                    formGroupClass="mb-0"
                                                    ariaLabel="Study Type"
                                                    name={`personalProperty.classifications[${classification}].studyType`}>
                  <option value="">Select</option>
                  {STUDY_TYPES_PERSONAL_4018.map(studyType => {
                    return <option key={studyType} value={studyType}>{studyType}</option>;
                  })}
                </FormikSelect>
                }
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && formatInteger(classificationData.assessmentRollClassificationAssessedValue)}
                {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                         maxLength="15"
                                                         className="text-center"
                                                         disableFloatingLabel={true}
                                                         ariaLabel="Assessment Roll Classification Assessed Value"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         name={`personalProperty.classifications[${classification}].assessmentRollClassificationAssessedValue`}/>}
              </td>
              <td className="align-middle text-center border-left-3">
                {showReadOnlyView && formatInteger(classificationData.numberOfParcels)}
                {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                         maxLength="9"
                                                         className="text-center"
                                                         disableFloatingLabel={true}
                                                         ariaLabel="Number of Parcels"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         name={`personalProperty.classifications[${classification}].numberOfParcels`}/>}
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && formatInteger(classificationData.sampleAssessedValue)}
                {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                         maxLength="15"
                                                         disableFloatingLabel={true}
                                                         className="text-center"
                                                         ariaLabel="Sample Assessed Value"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         name={`personalProperty.classifications[${classification}].sampleAssessedValue`}/>}
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && formatInteger(classificationData.sampleTrueCashValue)}
                {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                         maxLength="15"
                                                         disableFloatingLabel={true}
                                                         className="text-center"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         ariaLabel="Sample True Cash Value"
                                                         name={`personalProperty.classifications[${classification}].sampleTrueCashValue`}/>}
              </td>
              <td className="align-middle text-center border-left-3">
                {showReadOnlyView && formatDecimal(classificationData.studyRatio as number / 100, 2, true)}
                {!showReadOnlyView && <FormikPercentageInput formGroupClass="mb-0"
                                                             maxLength="7"
                                                             className="text-center"
                                                             disableFloatingLabel={true}
                                                             ariaLabel="Study Ratio"
                                                             onChange={() => setHasUnsavedChanges(true)}
                                                             name={`personalProperty.classifications[${classification}].studyRatio`}/>}
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && formatInteger(classificationData.projectedTrueCashValue)}
                {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                         maxLength="15"
                                                         className="text-center"
                                                         disableFloatingLabel={true}
                                                         ariaLabel="Projected True Cash Value"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         name={`personalProperty.classifications[${classification}].projectedTrueCashValue`}/>}
              </td>
            </tr>;
          })}
        </tbody>
        <tfoot>
          <tr className="bg-light">
            <td className="text-center align-middle text-primary font-weight-bold">Totals</td>
            <td/>
            <td className="text-center align-middle font-weight-bold">
              {formatInteger(totals.personalProperty.assessmentRollClassificationAssessedValue)}
            </td>
            <td className="text-center align-middle font-weight-bold border-left-3">
              {formatInteger(totals.personalProperty.numberOfParcels)}
            </td>
            <td/>
            <td/>
            <td className="border-left-3 text-center align-middle font-weight-bold">
              {formatInteger(totals.personalProperty.projectedTrueCashValue)}
            </td>
            <td/>
          </tr>
        </tfoot>
      </Table>
    </div>
    <Row>
      <Col>
        {showReadOnlyView && <>
          Remarks<br/>
          {form4018V2Dto.personalProperty.remarks}
        </>}
        {!showReadOnlyView && <FormikInput maxLength="240"
                                           onChange={() => setHasUnsavedChanges(true)}
                                           labelText="Remarks"
                                           name="personalProperty.remarks"/>}
      </Col>
    </Row>
  </>;
};

export default Form4018V2PersonalPropertyTab;