import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Row, Table} from 'reactstrap';
import {Formik, FormikProps} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  FormikInput,
  FormikNumberInput,
  FormikPercentageInput,
  ProgressIndicator,
  TabNav,
  useAlerts,
  useTabNav,
  withTabNav
} from '@reasoncorp/kyber-js';

import {megApi} from '../../api';
import * as messages from '../../messages';
import {formatDecimal, formatInteger, isNumber, PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import {cedRecapSchema} from '../../schemas/mega';
import {County, mega} from '../../types';
import {cedRecapApi} from '../../api/mega';

const mapValues = (cedRecapReport: mega.CedRecapReport) => {
  let cedRecapRequest: mega.CedRecapRequest = {
    comments: {
      '100': {},
      '200': {},
      '300': {},
      '400': {},
      '500': {},
      '600': {}
    },
    trueCashValueProjection: {
      '100': null,
      '200': null,
      '300': null,
      '400': null,
      '500': null,
      '600': null
    },
    studyTypeSummary: {
      '100': null,
      '200': null,
      '300': null,
      '400': null,
      '500': null,
      '600': null
    },
    qaRatio: {
      '100': null,
      '200': null,
      '300': null,
      '400': null,
      '500': null,
      '600': null
    }
  };


  for (let i = 100; i <= 600; i += 100) {
    let classification = i.toString();

    if (!cedRecapReport?.[classification]) {
      continue;
    }

    let comments: {[localUnitId: string]: string} = {};
    if (cedRecapReport[classification] && classification === '400') {
      (cedRecapReport[classification] as mega.CedRecapReportResidential).localUnits.forEach((localUnit) => {
        comments[localUnit.localUnitId] = localUnit.comments || '';
      });
    } else if (cedRecapReport[classification] && classification !== '400') {
      (cedRecapReport[classification] as mega.CedRecapReportClassification).localUnits.forEach((localUnit) => {
        comments[localUnit.localUnitId] = localUnit.comments || '';
      });
    }

    cedRecapRequest.comments[classification] = comments;
    cedRecapRequest.trueCashValueProjection[classification] = cedRecapReport?.[classification]?.trueCashValueProjection;
    cedRecapRequest.qaRatio[classification] = cedRecapReport?.[classification]?.qaRatio;
    cedRecapRequest.studyTypeSummary[classification] = cedRecapReport?.[classification]?.studyTypeSummary;
  }

  return cedRecapRequest;
};

const CedRecap = () => {
  const navigate = useNavigate();
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [county, setCounty] = useState<County | undefined>(undefined);
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {selectedTab} = useTabNav();
  const [cedRecapReport, setCedRecapReport] = useState<mega.CedRecapReport>(undefined);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: county?.displayName ?? '', active: true}
  ]), [
    year,
    countyId,
    county
  ]);

  useEffect(() => {
    const loadCedRecapReport = async () => {
      try {
        const [counties, cedRecapData] = await Promise.all([
          megApi.findCounties(true),
          cedRecapApi.find(countyId, year)
        ]);
        const currentCounty = counties.filter((county: County) => county.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal/analytics');
        }
        setCounty(currentCounty);
        setCedRecapReport(cedRecapData);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadCedRecapReport();
  }, [countyId, showErrorAlert, year, navigate]);

  const classificationLocalUnits = useMemo(() => {
    return cedRecapReport?.[selectedTab as string]?.localUnits ?? undefined;
  }, [selectedTab, cedRecapReport]);

  const classificationTotals = useMemo(() => {
    return cedRecapReport?.[selectedTab as string]?.totals ?? undefined;
  }, [selectedTab, cedRecapReport]);

  const additionalValues = useMemo(() => {
    return cedRecapReport?.[selectedTab as string] ?? undefined;
  }, [selectedTab, cedRecapReport]);

  const cedRecapReportClassificationTotals = useMemo(() => {
    return classificationTotals as mega.CedRecapReportClassificationTotals;
  }, [classificationTotals]);

  const cedRecapReportResidentialTotals = useMemo(() => {
    return (classificationTotals as mega.CedRecapReportResidentialTotals);
  }, [classificationTotals]);

  const calculateAdditionalValues = useCallback((formikProps: FormikProps<mega.CedRecapRequest>) => {
    const cedTrueCashValueProjection = formikProps.values.trueCashValueProjection[selectedTab as string];
    let psdTrueCashValueProjection: number | null;
    if (cedRecapReportClassificationTotals && cedRecapReportClassificationTotals.trueCashValue2 !== undefined) {
      psdTrueCashValueProjection = cedRecapReportClassificationTotals.trueCashValue2;
    } else {
      psdTrueCashValueProjection = cedRecapReportResidentialTotals.projectedTrueCashValue;
    }

    return {
      trueCashValueDifference: (cedTrueCashValueProjection || 0) - psdTrueCashValueProjection,
      trueCashValueRatio: psdTrueCashValueProjection !== 0 &&
      cedTrueCashValueProjection !== null &&
      isNumber(cedTrueCashValueProjection) ?
        cedTrueCashValueProjection / psdTrueCashValueProjection : 0
    };
  }, [
    cedRecapReportClassificationTotals,
    cedRecapReportResidentialTotals?.projectedTrueCashValue,
    selectedTab
  ]);

  const handleSave = useCallback(async (cedRecapRequest: mega.CedRecapRequest) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await cedRecapApi.update(countyId, year, cedRecapRequest);

      const cedRecapData = await cedRecapApi.find(countyId, year);
      setCedRecapReport(cedRecapData);
      showSuccessAlert(messages.REPORT_SAVE_SUCCESSFUL);
      setHasUnsavedChanges(false);
    } catch (error) {
      showErrorAlert(messages.REPORT_SAVE_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    countyId,
    year
  ]);

  return <Container fluid className="CedRecap">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && cedRecapReport && classificationLocalUnits && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Formik validationSchema={cedRecapSchema}
              initialValues={{...mapValues(cedRecapReport)}}
              validateOnMount={true}
              onSubmit={async () => null}
              enableReinitialize={true}>
        {(formikProps) => {
          const calculatedValues = calculateAdditionalValues(formikProps);
          return <>
            <Card className="mb-4">
              <CardHeader>CED Recap</CardHeader>
              <CardHeader className="nav-tabs-header">
                <TabNav/>
              </CardHeader>
              <CardBody>
                {selectedTab !== '400' && <div className="table-fixed mb-4 SplitTable">
                  <Table bordered responsive>
                    <thead>
                      <tr className="text-primary bg-light">
                        <th className="bg-light"/>
                        <th colSpan={10} className="text-primary bg-light text-center align-middle">The CED Unit Studies</th>
                        <th colSpan={3} className="text-primary border-left-3 bg-light-teal text-center align-middle">PSD Indicated Tentative</th>
                        <th className="bg-light-teal"/>
                      </tr>
                      <tr className="bg-light position-top-50">
                        <th className="bg-light text-primary text-center align-middle">Unit #</th>
                        <th className="bg-light text-primary text-left align-middle">Unit Name</th>
                        <th className="bg-light text-primary text-center align-middle">Study Type</th>
                        <th className="bg-light text-primary text-center align-middle"># of Parcels</th>
                        <th className="bg-light text-primary text-center align-middle">Total Parcels</th>
                        <th className="bg-light text-primary text-center align-middle">% Sampled</th>
                        <th className="bg-light text-primary text-center align-middle">Assessed Value</th>
                        <th className="bg-light text-primary text-center align-middle">True Cash Value</th>
                        <th className="bg-light text-primary text-center align-middle">Total Assessed Value</th>
                        <th className="bg-light text-primary text-center align-middle">% Sampled</th>
                        <th className="bg-light text-primary text-center align-middle">2793 L-4018 Ratio</th>
                        <th className="border-left-3 bg-light-teal text-primary text-center align-middle">L-4018 Ratio</th>
                        <th className="bg-light-teal text-primary text-center align-middle">True Cash Value</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Multiplier</th>
                        <th className="bg-light-teal text-primary text-center align-middle">PSD Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (classificationLocalUnits as mega.CedRecapReportLocalUnit[]).map((item) => {
                          return <tr key={item.localUnitId}>
                            <td className="text-center text-primary font-weight-bold align-middle">{item.localUnitId}</td>
                            <td className="text-left text-primary font-weight-bold text-nowrap align-middle">{item.localUnitName}</td>
                            <td className="text-center align-middle">{item.studyType}</td>
                            <td className="text-center align-middle">{formatInteger(item.numberOfParcels)}</td>
                            <td className="text-center align-middle">{formatInteger(item.totalParcels)}</td>
                            <td className="text-center align-middle">{formatDecimal(item.percentSampled1, 0, true)}</td>
                            <td className="text-center align-middle">{formatInteger(item.assessedValue)}</td>
                            <td className="text-center align-middle">{formatInteger(item.trueCashValue)}</td>
                            <td className="text-center align-middle">{formatInteger(item.totalAssessedValue)}</td>
                            <td className="text-center align-middle">{formatDecimal(item.percentSampled2, 0, true)}</td>
                            <td className="text-center align-middle">{formatDecimal(item.ratioL2793L4018, 2, true)}</td>
                            <td className="border-left-3 bg-light-teal text-center align-middle">{formatDecimal(item.ratioL4018, 2, true)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.trueCashValue2)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatDecimal(item.multiplier, 4)}</td>
                            <td className="bg-light-teal text-center align-middle">
                              <label style={{display: 'none'}}
                                     htmlFor={`comments['${selectedTab}'][${item.localUnitId}]`}>
                                Comments
                              </label>
                              <FormikInput name={`comments['${selectedTab}'][${item.localUnitId}]`}
                                           maxLength="200"
                                           disableFloatingLabel={true}
                                           formGroupClass="mb-0"
                                           ariaLabel="Comments"
                                           className="shaded-comments"
                                           onChange={() => {
                                             setHasUnsavedChanges(true);
                                           }}/>
                            </td>
                          </tr>;
                        })
                      }
                    </tbody>
                    {classificationTotals && <tfoot>
                      <tr key={`${selectedTab}-totals`} className="bg-light font-weight-bold">
                        <td className="text-center align-middle text-dark bg-light">Totals</td>
                        <td className="align-middle bg-light"/>
                        <td className="text-center align-middle bg-light">{cedRecapReportClassificationTotals.studyType}</td>
                        <td className="text-center align-middle bg-light">{formatInteger(cedRecapReportClassificationTotals.numberOfParcels)}</td>
                        <td className="text-center align-middle bg-light">{formatInteger(cedRecapReportClassificationTotals.totalParcels)}</td>
                        <td className="text-center align-middle bg-light">{formatDecimal(cedRecapReportClassificationTotals.percentSampled1, 0, true)}</td>
                        <td className="text-center align-middle bg-light">{formatInteger(cedRecapReportClassificationTotals.assessedValue)}</td>
                        <td className="text-center align-middle bg-light">{formatInteger(cedRecapReportClassificationTotals.trueCashValue)}</td>
                        <td className="text-center align-middle bg-light">{formatInteger(cedRecapReportClassificationTotals.totalAssessedValue)}</td>
                        <td className="text-center align-middle bg-light">{formatDecimal(cedRecapReportClassificationTotals.percentSampled2, 0, true)}</td>
                        <td className="align-middle bg-light"/>
                        <td className="border-left-3 bg-light-teal text-center align-middle">{formatDecimal(cedRecapReportClassificationTotals.ratioL4018, 2, true)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportClassificationTotals.trueCashValue2)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatDecimal(cedRecapReportClassificationTotals.multiplier, 4)}</td>
                        <td className="bg-light-teal align-middle"/>
                      </tr>
                    </tfoot>}
                  </Table>
                </div>}
                {selectedTab === '400' && <div className="table-fixed mb-4 SplitTable">
                  <Table bordered responsive>
                    <thead>
                      <tr className="bg-light">
                        <th colSpan={2} className="bg-light"/>
                        <th colSpan={1} className="bg-light-teal border-left-3"/>
                        <th colSpan={5} className="bg-light-teal text-primary text-center align-middle"># of Parcels</th>
                        <th colSpan={3} className="border-left-3 bg-light"/>
                        <th colSpan={4} className="bg-light-teal border-left-3 text-primary text-center align-middle">Adjusted Assessed Values</th>
                        <th colSpan={1} className="bg-light-teal"/>
                        <th colSpan={7} className="border-left-3 bg-light"/>
                      </tr>
                      <tr className="bg-light position-top-50">
                        <th className="bg-light text-primary text-center align-middle">Unit #</th>
                        <th className="bg-light text-primary text-left align-middle">Unit Name</th>
                        <th className="border-left-3 bg-light-teal text-primary text-center align-middle">Study Type</th>
                        <th className="bg-light-teal text-primary text-center align-middle">First Period</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Second Period</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Third Period</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Fourth Period</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Appraisal</th>
                        <th className="border-left-3 bg-light text-primary text-center align-middle">Study # Parcels</th>
                        <th className="bg-light text-primary text-center align-middle">Unit Total Parcels</th>
                        <th className="bg-light text-primary text-center align-middle">Sample Size Percent</th>
                        <th className="border-left-3 bg-light-teal text-primary text-center align-middle">First Period</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Second Period</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Third Period</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Fourth Period</th>
                        <th className="bg-light-teal text-primary text-center align-middle">Appraisal Study Assessed Value</th>
                        <th className="border-left-3 bg-light text-primary text-center align-middle">Study Assessed Value</th>
                        <th className="bg-light text-primary text-center align-middle">Unit Total Assessed Value</th>
                        <th className="bg-light text-primary text-center align-middle">Sample Size Percent</th>
                        <th className="bg-light text-primary text-center align-middle">L4018 Ratio</th>
                        <th className="bg-light text-primary text-center align-middle">Projected TCV</th>
                        <th className="bg-light text-primary text-center align-middle">Tentative Mult.</th>
                        <th className="bg-light text-primary text-center align-middle">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (classificationLocalUnits as mega.CedRecapReportLocalUnitResidential[]).map((item: mega.CedRecapReportLocalUnitResidential) => {
                          return <tr key={item.localUnitId}>
                            <td className="text-center align-middle text-primary font-weight-bold">{item.localUnitId}</td>
                            <td className="text-left text-nowrap align-middle text-primary font-weight-bold">{item.localUnitName}</td>
                            <td className="border-left-3 bg-light-teal text-center align-middle">{item.studyType}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.firstPeriodNumberOfParcels)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.secondPeriodNumberOfParcels)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.thirdPeriodNumberOfParcels)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.fourthPeriodNumberOfParcels)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.appraisalNumberOfParcels)}</td>
                            <td className="border-left-3 text-center align-middle">{formatInteger(item.studyNumberOfParcels)}</td>
                            <td className="text-center align-middle">{formatInteger(item.unitTotalParcels)}</td>
                            <td className="text-center align-middle">{formatDecimal(item.numberOfParcelsSampleSizePercent, 0, true)}</td>
                            <td className="border-left-3 bg-light-teal text-center align-middle">{formatInteger(item.firstPeriodAdjustedAssessedValue)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.secondPeriodAdjustedAssessedValue)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.thirdPeriodAdjustedAssessedValue)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.fourthPeriodAdjustedAssessedValue)}</td>
                            <td className="bg-light-teal text-center align-middle">{formatInteger(item.appraisalStudyAssessedValue)}</td>
                            <td className="border-left-3 text-center align-middle">{formatInteger(item.studyAssessedValue)}</td>
                            <td className="text-center align-middle">{formatInteger(item.unitTotalAssessedValue)}</td>
                            <td className="text-center align-middle">{formatDecimal(item.adjustedAssessedValueSampleSizePercent, 2, true)}</td>
                            <td className="text-center align-middle">{formatDecimal(item.ratioL4018, 2, true)}</td>
                            <td className="text-center align-middle">{formatInteger(item.projectedTrueCashValue)}</td>
                            <td className="text-center align-middle">{formatDecimal(item.tentativeMultiplier, 4)}</td>
                            <td className="text-center align-middle">
                              <FormikInput
                                name={`comments['${selectedTab}'][${item.localUnitId}]`}
                                maxLength="200"
                                disableFloatingLabel={true}
                                formGroupClass="mb-0"
                                ariaLabel="Comments"
                                onChange={() => {
                                  setHasUnsavedChanges(true);
                                }}/>
                            </td>
                          </tr>;
                        })
                      }
                    </tbody>
                    {classificationTotals && <tfoot>
                      <tr key={`${selectedTab}-totals`} className="font-weight-bold text-dark">
                        <td className="text-center align-middle bg-light">Totals</td>
                        <td className="align-middle bg-light"/>
                        <td className="border-left-3 bg-light-teal text-center align-middle">{cedRecapReportResidentialTotals.studyType}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.firstPeriodNumberOfParcels)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.secondPeriodNumberOfParcels)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.thirdPeriodNumberOfParcels)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.fourthPeriodNumberOfParcels)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.appraisalNumberOfParcels)}</td>
                        <td className="border-left-3 bg-light text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.studyNumberOfParcels)}</td>
                        <td className="bg-light text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.unitTotalParcels)}</td>
                        <td className="bg-light text-center align-middle">{formatDecimal(cedRecapReportResidentialTotals.numberOfParcelsSampleSizePercent, 0, true)}</td>
                        <td className="border-left-3 bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.firstPeriodAdjustedAssessedValue)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.secondPeriodAdjustedAssessedValue)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.thirdPeriodAdjustedAssessedValue)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.fourthPeriodAdjustedAssessedValue)}</td>
                        <td className="bg-light-teal text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.appraisalStudyAssessedValue)}</td>
                        <td className="border-left-3 bg-light text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.studyAssessedValue)}</td>
                        <td className="bg-light text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.unitTotalAssessedValue)}</td>
                        <td className="bg-light text-center align-middle">{formatDecimal(cedRecapReportResidentialTotals.adjustedAssessedValueSampleSizePercent, 2, true)}</td>
                        <td className="bg-light text-center align-middle">{formatDecimal(cedRecapReportResidentialTotals.ratioL4018, 2, true)}</td>
                        <td className="bg-light text-center align-middle">{formatInteger(cedRecapReportResidentialTotals.projectedTrueCashValue)}</td>
                        <td className="bg-light text-center align-middle">{formatDecimal(cedRecapReportResidentialTotals.tentativeMultiplier, 4)}</td>
                        <td className="bg-light align-middle"/>
                      </tr>
                    </tfoot>
                    }
                  </Table>
                </div>}
                <Table bordered responsive>
                  <thead>
                    <tr className="bg-light">
                      <th className="text-primary text-center align-middle">Percent of Class Studied</th>
                      <th className="text-primary text-center align-middle">CED L-4018 TCV Projection</th>
                      <th className="text-primary text-center align-middle">Summary of CED Study Types</th>
                      <th className="text-primary text-center align-middle">CED OA Ratio</th>
                      <th className="text-primary text-center align-middle">The CED-PSD TCV Difference</th>
                      <th className="text-primary text-center align-middle">CED TCV/PSD TCV</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={`${selectedTab}-additional-values`}>
                      <td className="text-center align-middle">{formatDecimal(additionalValues?.percentOfClassStudied ?? null, 2, true)}</td>
                      <td className="text-center align-middle">
                        <FormikNumberInput name={`trueCashValueProjection['${selectedTab}']`}
                                           maxLength="15"
                                           formGroupClass="mb-0"
                                           disableFloatingLabel={true}
                                           className="text-center"
                                           ariaLabel="CED L-4018 TCV Projection"
                                           onChange={() => {
                                             setHasUnsavedChanges(true);
                                           }}/>
                      </td>
                      <td className="text-center align-middle">
                        <FormikInput name={`studyTypeSummary['${selectedTab}']`}
                                     maxLength="25"
                                     formGroupClass="mb-0"
                                     className="text-center"
                                     disableFloatingLabel={true}
                                     ariaLabel="Summary of CED Study Types"
                                     onChange={() => {
                                       setHasUnsavedChanges(true);
                                     }}/>
                      </td>
                      <td className="text-center align-middle">
                        <FormikPercentageInput maxLength="6"
                                               ariaLabel="CED OA Ratio"
                                               className="text-center"
                                               disableFloatingLabel={true}
                                               name={`qaRatio['${selectedTab}']`}
                                               formGroupClass="mb-0"
                                               onChange={() => {
                                                 setHasUnsavedChanges(true);
                                               }}/>
                      </td>
                      <td className="text-center align-middle">{formatInteger(calculatedValues.trueCashValueDifference)}</td>
                      <td className="text-center align-middle">{formatDecimal(calculatedValues.trueCashValueRatio, 1, true)}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button color="success"
                        disabled={loadingState.processing || !hasUnsavedChanges || !formikProps.isValid}
                        onClick={() => handleSave(formikProps.values)}>
                  Save
                </Button>
              </Col>
            </Row>
          </>;
        }}
      </Formik>
    </>}
  </Container>;
};

export default withTabNav(CedRecap, {tabs: PROPERTY_CLASSIFICATION_TABS});