import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {useMemo} from 'react';

import {Alerts, MiFooter, NotFoundV2, SkipNav, useUserContext} from '@reasoncorp/kyber-js';

import './font-awesome-icons';
import {
  CountyFilingCabinet,
  CountyForm,
  CountyFormPdfList,
  CountyPortal,
  LocalUnitFilingCabinet,
  LocalUnitForm,
  LocalUnitFormList,
  LocalUnitFormPdfList,
  LocalUnitPortal,
  StateFilingCabinet,
  StateLocalUnitFormList,
  StatePortal,
  StudyCertification
} from './containers';
import {
  CedRecap,
  CountyReport4017,
  LocalUnitReport4017,
  MasterSales,
  MegaPortal,
  Modifiers,
  MonitorStudy,
  PersonalPropertyReport,
  PsdMasterSales,
  PsdSalesSample,
  RecapComparisonReport,
  Report4030,
  Report4030Admin,
  Report4030AdminDashboard,
  Statistics
} from './containers/mega';
import {MegAppBar} from './components';

const App = () => {
  const {currentUser, loadingUser, permissions} = useUserContext();
  const location = useLocation();

  const rootPath = useMemo(() => {
    if (permissions.isStateUser) {
      return <Navigate to="/state-portal/forms" replace/>;
    } else if (permissions.isCountyUser) {
      return <Navigate to="/county-portal" replace/>;
    } else {
      return <Navigate to="/local-unit-portal" replace/>;
    }
  }, [permissions.isStateUser, permissions.isCountyUser]);

  const isStatePortal = useMemo(() => location.pathname.includes('state-portal'), [location.pathname]);

  return <>
    <SkipNav/>
    <header>
      <MegAppBar currentUser={currentUser}
                 isAdmin={permissions.isAdmin}
                 isStatePortal={isStatePortal}/>
    </header>
    {!loadingUser &&
      <main id="content" role="main">
        <Routes>
          {permissions.isCountyUser && <>
            <Route path="/county-portal/:countyId/:year/forms/:countyFormId/local-units/:localUnitId/forms/:localUnitFormId" element={
              <LocalUnitForm/>}/>
            <Route path="/county-portal/:countyId/:year/forms/:countyFormId/local-units" element={
              <LocalUnitFormList/>}/>
            <Route path="/county-portal/:countyId/:year/forms/:countyFormId/local-units/:localUnitId/forms/:localUnitFormId/pdfs" element={
              <LocalUnitFormPdfList/>}/>
            <Route path="/county-portal/:countyId/:year/forms/:countyFormId" element={<CountyForm/>}/>
            <Route path="/county-portal/:countyId/:year/forms/:countyFormId/pdfs" element={<CountyFormPdfList/>}/>
            <Route path="/county-portal/:countyId/:year/filing-cabinet" element={<CountyFilingCabinet/>}/>
            <Route path="/county-portal/:countyId/:year" element={<CountyPortal/>}/>
            <Route path="/county-portal" element={<CountyPortal/>}/>
            <Route path="/study-certifications/:countyId/:year" element={<StudyCertification/>}/>
          </>}
          {permissions.isLocalUnitUser && <>
            <Route path="/local-unit-portal/:localUnitId/:year/filing-cabinet" element={<LocalUnitFilingCabinet/>}/>
            <Route path="/local-unit-portal/:localUnitId/:year/forms/:localUnitFormId" element={
              <LocalUnitForm/>}/>
            <Route path="/local-unit-portal/:localUnitId/:year/forms/:localUnitFormId/pdfs" element={
              <LocalUnitFormPdfList/>}/>
            <Route path="/local-unit-portal/:localUnitId/:year" element={<LocalUnitPortal/>}/>
            <Route path="/local-unit-portal" element={<LocalUnitPortal/>}/>
          </>}
          {permissions.isAdmin && <>
            <Route path="/state-portal/report-4030/admin/:year/:countyId" element={<Report4030Admin/>}/>
            <Route path="/state-portal/report-4030/admin/:year" element={<Report4030AdminDashboard/>}/>
            <Route path="/state-portal/report-4030/admin" element={<Report4030AdminDashboard/>}/>
          </>}
          {permissions.isStateUser && <>
            <Route path="/state-portal/analytics/:year/:countyId/report-4030" element={<Report4030/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/master-sales" element={<MasterSales/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/monitor-study" element={<MonitorStudy/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/statistics" element={<Statistics/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/personal-property" element={
              <PersonalPropertyReport/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/recap-comparison-report" element={
              <RecapComparisonReport/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/modifiers" element={<Modifiers/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/psd-master-sales/year1" element={
              <PsdMasterSales/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/psd-master-sales/year2" element={
              <PsdMasterSales/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/ced-recap" element={<CedRecap/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/county-report-4017" element={<CountyReport4017/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/local-unit-report-4017" element={
              <LocalUnitReport4017/>}/>
            <Route path="/state-portal/analytics/:year/:countyId/psd-sales-sample" element={<PsdSalesSample/>}/>
            <Route path="/state-portal/analytics/:year/:countyId" element={<MegaPortal/>}/>
            <Route path="/state-portal/analytics/:year" element={<MegaPortal/>}/>
            <Route path="/state-portal/analytics" element={<MegaPortal/>}/>
            <Route path="/state-portal/:countyId/:year/forms/:countyFormId/local-units/forms/" element={
              <StateLocalUnitFormList isLocalUnit4022={false}/>}/>
            <Route path="/state-portal/:countyId/:year/forms/:countyFormId/local-units/forms-local-unit" element={
              <StateLocalUnitFormList isLocalUnit4022={true}/>}/>
            <Route path="/state-portal/:countyId/:year/forms/:countyFormId" element={<CountyForm/>}/>
            <Route path="/state-portal/:countyId/:year/forms/:countyFormId/pdfs" element={<CountyFormPdfList/>}/>
            <Route path="/state-portal/:countyId/:year/forms/:countyFormId/local-units/:localUnitId/forms/:localUnitFormId" element={
              <LocalUnitForm/>}/>
            <Route path="/state-portal/:countyId/:year/forms/:countyFormId/local-units/:localUnitId/forms/:localUnitFormId/pdfs" element={
              <LocalUnitFormPdfList/>}/>
            <Route path="/state-portal/:countyId/:year/forms/:countyFormId/local-units/:localUnitId/forms-local-unit/:localUnitFormId/pdfs" element={
              <LocalUnitFormPdfList isLocalUnit4022={true}/>}/>
            <Route path="/state-portal/:countyId/:year/forms/:countyFormId/local-units/:localUnitId/forms-local-unit/:localUnitFormId" element={
              <LocalUnitForm isStateViewLocalUnit4022={true}/>}/>
            <Route path="/state-portal/:countyId/:year/filing-cabinet" element={<StateFilingCabinet/>}/>
            <Route path="/state-portal/forms/:year" element={<StatePortal/>}/>
            <Route path="/state-portal/forms" element={<StatePortal/>}/>
            <Route path="/state-portal" element={<Navigate to="/state-portal/forms" replace/>}/>
            <Route path="/study-certifications/:countyId/:year" element={<StudyCertification/>}/>
          </>}
          <Route path="/" element={rootPath}/>
          <Route path="*" element={<NotFoundV2/>}/>
        </Routes>
      </main>}
    <MiFooter/>
    <Alerts/>
  </>;
};

export default App;