import {memo, useCallback, useMemo} from 'react';

import {AppBar, AppBarRoute, SOMLogo, SsoNotificationButton, User, UserProfile} from '@reasoncorp/kyber-js';

import config from '../config';
import {ssoApi} from '../api';

type Props = {
  currentUser?: User
  isAdmin?: boolean
  isStatePortal?: boolean
}

const MegAppBar = ({
                     currentUser,
                     isAdmin,
                     isStatePortal
                   }: Props) => {

  const handleProfileClick = useCallback(() => {
    window.location.href = `${config?.sso?.webUrl}/user-profile`;
  }, []);

  const handleNotificationsClick = useCallback(() => {
    window.location.href = `${config?.sso?.webUrl}/notifications`;
  }, []);

  const handleSignOut = useCallback(async () => await ssoApi.signOut(), []);

  const renderNotificationButton = useMemo(() => {
    return currentUser ? () =>
      <SsoNotificationButton onNotificationsClick={handleNotificationsClick}
                             ssoApi={ssoApi}/> : undefined;
  }, [currentUser, handleNotificationsClick]);

  const renderUserProfile = useMemo(() => {
    return currentUser ? () => <UserProfile handleProfileClick={handleProfileClick}
                                            handleSignOut={handleSignOut}/> : undefined;
  }, [currentUser, handleProfileClick, handleSignOut]);

  const routes = useMemo(() => ([
    {
      name: 'Forms',
      to: '/state-portal/forms',
      hide: !isStatePortal
    },
    {
      name: 'Analytics',
      to: '/state-portal/analytics',
      hide: !isStatePortal
    },
    {
      name: '4030 Admin',
      to: '/state-portal/report-4030/admin',
      hide: !isAdmin || !isStatePortal
    }
  ] as AppBarRoute[]), [
    isAdmin,
    isStatePortal
  ]);

  const appBarProps = useMemo(() => ({
    brandLink: `${config.sso.webUrl}/dashboard`,
    brandImage: SOMLogo,
    brandImageAlt: 'MiSuite',
    appName: 'Michigan Equalization Gateway',
    organizationName: 'The Department of Treasury',
    environmentName: config.envName !== 'prod' ? config.envName : undefined,
    routes,
    renderNotificationButton,
    renderUserProfile
  }), [routes, renderNotificationButton, renderUserProfile]);

  return <AppBar {...appBarProps}/>;
};

export default memo(MegAppBar);
