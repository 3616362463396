import {mega} from '../../types';
import {megApi} from '../apiUtils';

export const find = (countyId: number | string,
                     year: number | string
): Promise<mega.CedRecapReport> => megApi.getWithJsonResponse(
  `mega/counties/${countyId}/ced-recap?year=${year}`
);

export const update = (countyId: number | string,
                       year: number | string,
                       cedRecapRequest: mega.CedRecapRequest
): Promise<mega.CedRecapReport> => megApi.patchWithJsonResponse(
  `/mega/counties/${countyId}/ced-recap?year=${year}`,
  {body: JSON.stringify(cedRecapRequest)}
);
