import * as Yup from 'yup';

import * as messages from '../../messages';

const commentsSchema = Yup.object({
  localUnits: Yup.array(Yup.object({
    comments: Yup.string()
      .max(200, messages.maxCharactersMessage(200))
      .matches(/^$|^[a-z\d- ]+$/i, messages.MUST_BE_ALPHANUMERIC)
      .nullable()
  }))
});

const trueCashValueProjectionSchema = Yup.number()
  .typeError(messages.MUST_BE_NUMBER)
  .min(0, messages.MUST_NOT_BE_NEGATIVE)
  .max(999999999999, messages.maxValueMessage(999999999999))
  .nullable();

const studyTypeSummarySchema = Yup.string()
  .max(25, messages.maxCharactersMessage(25))
  .matches(/^$|^[a-z\d- ]+$/i, messages.MUST_BE_ALPHANUMERIC)
  .nullable();

const qaRatioSchema = Yup.number()
  .typeError(messages.MUST_BE_NUMBER)
  .min(0, messages.MUST_NOT_BE_NEGATIVE)
  .max(99.99, messages.maxValueMessage(99.99))
  .nullable();

const classificationsSchema = (schema: Yup.BaseSchema) => {
  return Yup.object({
    '100': schema,
    '200': schema,
    '300': schema,
    '400': schema,
    '500': schema,
    '600': schema
  });
};

export default Yup.object().shape({
  cedRecapReport: Yup.object({
    comments: classificationsSchema(commentsSchema),
    values: Yup.object({
      trueCashValueProjection: classificationsSchema(trueCashValueProjectionSchema),
      studyTypeSummary: classificationsSchema(studyTypeSummarySchema),
      qaRatio: classificationsSchema(qaRatioSchema)
    })
  })
});