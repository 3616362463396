import {PropertyClassificationFilter, StudyPeriods} from '../types';

export const INSTRUMENT_TYPES = [
  'WD',
  'SD',
  'QC',
  'PTA',
  'OTH',
  'MLC',
  'LC',
  'CD',
  'AFF'
];

export const FINANCING_TYPES: string[] = [
  'C',
  'CR'
];

export const STUDY_TYPES_PERSONAL_PROPERTY: {[studyType: string]: string} = Object.freeze({
  'RV': 'Record Verification',
  'AU': 'Audit',
  'ES': 'Estimated Study',
  'NS': 'Not Studied'
});

export const STUDY_TYPES: {[studyType: string]: string} = Object.freeze({
  'AS': 'Appraisal Study',
  'S1': '1 Year Study',
  'S2': '2 Year Study',
  'CS': 'Combined Study',
  'ST': 'Stratified Study',
  'NC': 'None Classed',
  'ES': 'Estimated Study',
  'NS': 'Not Studied'
});

export const STUDY_TYPES_PERSONAL_4018: string[] = [
  'AU',
  'NC',
  'NW',
  'OH',
  'RV',
  'ES'
];

export const STUDY_TYPES_REAL_4018: string[] = [
  'AS',
  'NC',
  'ES',
  'SS',
  'CS',
  'ST'
];

export const CERTIFICATION_LEVELS: string[] = ['MMAO', 'MAAO', 'MCAO'];

export const PROPERTY_CLASSIFICATIONS = Object.freeze({
  'AGRICULTURAL': {displayValue: '100 Agricultural', filterValue: 100},
  'COMMERCIAL': {displayValue: '200 Commercial', filterValue: 200},
  'INDUSTRIAL': {displayValue: '300 Industrial', filterValue: 300},
  'RESIDENTIAL': {displayValue: '400 Residential', filterValue: 400},
  'TIMBER_CUTOVER': {displayValue: '500 Timber-Cutover', filterValue: 500},
  'DEVELOPMENTAL': {displayValue: '600 Developmental', filterValue: 600}
});

export const PROPERTY_CLASSIFICATION_TABS = [
  {value: '100', displayValue: '100 Agricultural'},
  {value: '200', displayValue: '200 Commercial'},
  {value: '300', displayValue: '300 Industrial'},
  {value: '400', displayValue: '400 Residential'},
  {value: '500', displayValue: '500 Timber-Cutover'},
  {value: '600', displayValue: '600 Developmental'}
];

export const CED_4015_TYPE: {[key: string]: string} = Object.freeze({
  'CONVENTIONAL': 'Conventional',
  'CREATIVE': 'Creative',
  'NOT USED': 'Not Used',
  'REFERENCE': 'Reference'
});

const filterByPropertyClassification = (items = [] as any[],
                                        propertyClassification: PropertyClassificationFilter) => {
  const minValue = propertyClassification.filterValue;
  const maxValue = propertyClassification.filterValue + 99;
  return items.filter(item => item['propertyClassification'] >= minValue &&
    item['propertyClassification'] <= maxValue);
};

export const filterPropertiesByClassification = (items = [] as any[]) => {
  return {
    [PROPERTY_CLASSIFICATIONS.AGRICULTURAL.filterValue.toString()]: filterByPropertyClassification(items, PROPERTY_CLASSIFICATIONS.AGRICULTURAL),
    [PROPERTY_CLASSIFICATIONS.COMMERCIAL.filterValue.toString()]: filterByPropertyClassification(items, PROPERTY_CLASSIFICATIONS.COMMERCIAL),
    [PROPERTY_CLASSIFICATIONS.INDUSTRIAL.filterValue.toString()]: filterByPropertyClassification(items, PROPERTY_CLASSIFICATIONS.INDUSTRIAL),
    [PROPERTY_CLASSIFICATIONS.RESIDENTIAL.filterValue.toString()]: filterByPropertyClassification(items, PROPERTY_CLASSIFICATIONS.RESIDENTIAL),
    [PROPERTY_CLASSIFICATIONS.TIMBER_CUTOVER.filterValue.toString()]: filterByPropertyClassification(items, PROPERTY_CLASSIFICATIONS.TIMBER_CUTOVER),
    [PROPERTY_CLASSIFICATIONS.DEVELOPMENTAL.filterValue.toString()]: filterByPropertyClassification(items, PROPERTY_CLASSIFICATIONS.DEVELOPMENTAL)
  };
};

export const getPropertyClassificationDisplayFromFilter = (filterValue: number | string): string => {
  let value;
  for (value of Object.values(PROPERTY_CLASSIFICATIONS)) {
    if (value.filterValue === (filterValue as number)) {
      return value.displayValue;
    }
  }
  return '';
};

export const salesStudyPeriods = (equalizationYear: number): StudyPeriods => {
  return {
    1: {
      begin: new Date(`04/01/${equalizationYear - 3}`),
      end: new Date(`09/30/${equalizationYear - 3}`)
    },
    2: {
      begin: new Date(`10/01/${equalizationYear - 3}`),
      end: new Date(`03/31/${equalizationYear - 2}`)
    },
    3: {
      begin: new Date(`04/01/${equalizationYear - 2}`),
      end: new Date(`09/30/${equalizationYear - 2}`)
    },
    4: {
      begin: new Date(`10/01/${equalizationYear - 2}`),
      end: new Date(`03/31/${equalizationYear - 1}`)
    },
    5: {
      begin: new Date(`04/01/${equalizationYear - 1}`),
      end: new Date(`09/30/${equalizationYear - 1}`)
    }
  };
};